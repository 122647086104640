import React, { Component } from 'react';
import Intro from './intro';
import Skills from './Skills';
import Project from './Project';
import './Home.css';

class Home extends Component {
	render() {
		return (
			<div className="Home">
				<Intro />
				<Skills />
				<Project />
			</div>
		);
	}
}

export default Home;
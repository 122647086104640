import React, { Component } from 'react';
import './Contact.css';

class Content extends Component {
	render() {
		return (
			<div className="Content">
				<div className="container">
					<div className="col-12">
						<h1 className="Intro-title">Arely Miramontes Rodríguez</h1>
					</div>
				</div>
				<div className="container Contact-wrap">
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>Contact</h2>
							<a href="https://github.com/ArelySkywalker" target="_blank" rel="noopener noreferrer" ><i className="fab fa-github"></i></a>
							<a href="https://www.linkedin.com/in/arelymiramontes/" target="_blank" rel="noopener noreferrer" ><i className="fab fa-linkedin-in"></i></a>
							<a href="https://twitter.com/ArelySkywalker" target="_blank" rel="noopener noreferrer" ><i className="fab fa-twitter"></i></a>
							<a href="mailto:arelymts@gmail.com">arelymts@gmail.com</a>
						</div>
						<div className="col-lg-9 Content-text">
							<p>Wanna have a chat or grab a beer? Shoot me an email and get in touch!</p>
							<p>It takes more than procrastinating real work by fixing minor bugs to be a great software engineer!</p>

							<form action="https://formspree.io/arelymts@gmail.com" method="POST">
								<div className="row">
									<div className="col-6 form group">
										<label htmlFor="name">Name:</label>
										<input id="name" type="text" name="name" />
									</div>
									<div className="col-6 form group">
										<label htmlFor="email">Email:</label>
										<input id="email" type="email" name="_replyto" />
									</div>
									<div className="col-12 form group">
										<label htmlFor="message">Message:</label>
										<textarea rows="7" id="message" type="textarea" name="message" />
										<input className="App-button" type="submit" value="Send" />
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Content;
import React, { Component } from 'react';
import './Projects.css';

class Skills extends Component {
	render() {
		return (				
			<div name="featProject" className="Project">
				<div className="container">
					<a href="/cocktail" className="row cocktail">
						<div className="col-12">
							<div className="Project-image"></div>
						</div>
						<div className="col-12 Project-meta">
							<h3>Cocktail Recipe Search App</h3><br />
							<p>Cocktail Search App that let's you search and filter for cocktails built with React.</p>
							<span className="App-button">View Project</span>
						</div>
					</a>
					<a href="/ad-extension" className="row arrested">
						<div className="col-12">
							<div className="Project-image"></div>
						</div>
						<div className="col-12 Project-meta">
							<h3>Arrested Development Chrome Extension</h3><br />
							<p>Chrome extension that listens for key words, and inserts quotes from the show Arrested Development based on the key word.</p>
							<span className="App-button">View Project</span>
						</div>
					</a>
					<a href="/todolistapp" className="row todoapp">
						<div className="col-12">
							<div className="Project-image"></div>
						</div>
						<div className="col-12 Project-meta">
							<h3>To-Do List App</h3><br />
							<p>Simple To-Do List App built with Vanilla JS to practice DOM manipulation</p>
							<span className="App-button">View Project</span>
						</div>
					</a>
				</div>
			</div>			
		);
	}
}

export default Skills;
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import tieFigher from './Images/tie-fighter-2.png';
import ship from './Images/ship-black.png';
import './Home.css';

class Intro extends Component {
	render() {
		return (
			<Fade bottom>
				<div className="Intro">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<img src={ tieFigher } alt="TIE Fighter" />
								<h1 className="Intro-title">Arely Miramontes Rodríguez</h1>
								<h2 className="Intro-description">I'm a Software Engineer Jedi at Amazon Web Services.<br />
								On weekends I fight crime and triumph over evil.<br />
								But you didn't hear it from me.</h2>
							</div>
						</div>
					</div>
					<Link to="myStack" spy={true} smooth={true}>
						<div className="Intro-next">
							<img src={ ship } alt="next" />
						</div>
					</Link>
				</div>
			</Fade>
		);
	}
}

export default Intro;
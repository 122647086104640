import React, { Component } from 'react';
import Banner from './Banner';
import './Single.css';

class Single extends Component {
	render() {
		return (				
			<div className="Single">
				<Banner id="cocktail" />
				<div className="Content">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 Content-title">
								<h2>Cocktail Recipe Search App</h2>
								<a href="https://github.com/ArelySkywalker/Six-Gummy-Bears-and-some-Scotch" target="_blank" rel="noopener noreferrer" className="App-button">View on Github</a>
								<a href="https://gummy-bears-and-some-scotch.firebaseapp.com" target="_blank" rel="noopener noreferrer" className="App-button">View App</a>
							</div>
							<div className="col-lg-9 Content-text">
								<p>I love hosting dinner parties, and one thing I struggle with is figuring out what specialty cocktail I want to make for my guests. So I decided to build myself a Cocktail app!</p>
								<p>Using the CocktailDB API, I came up with my own design and how I wanted to display my drinks. I picked 5 major ingrediets to be my "main" ingredients because these are what I usually have around.</p>
								<p>There's still a lot more to do! I plan on adding more features and functionality such as user login to save favorites, search inputs, etc.</p>
								<p>You can view the Demo <a href="https://gummy-bears-and-some-scotch.firebaseapp.com" target="_blank" rel="noopener noreferrer">here</a>!</p>
								<br />

								<h3>Happy Hacking!</h3>
							</div>
						</div>
					</div>
				</div>
			</div>			
		);
	}
}

export default Single;
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './Home.css';

class Skills extends Component {
	render() {
		return (
			<Fade bottom>
				<div name="featProject" className="Project">
					<div className="container">
						<div className="row cocktail">
							<div className="col-12 Project-title">
								<h2>Featured Project</h2>
							</div>
							<a href="/cocktail" className="col-12">
								<div className="Project-image"></div>
							</a>
							<a href="/cocktail" className="col-12 Project-meta">
								<h3>Cocktail Recipe Search App</h3><br />
								<p>Cocktail Search App that let's you search and filter for cocktails built with React.</p>
								<span className="App-button">View Project</span>
							</a>
						</div>
					</div>
				</div>
			</Fade>
			
		);
	}
}

export default Skills;